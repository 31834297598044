// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Button, Divider, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableRow, Tooltip, Typography, tableCellClasses,Switch } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { getTenantById,updateTenantStatus } from "lib/Api/Tenants";
import { useContext, useEffect, useState } from "react";
import LocalDataTable from "examples/Tables/LocalDataTable";
import DefaultCell from "examples/Tables/components/DefaultCell";
import { Download } from "@mui/icons-material";
import moment from "moment";
import { sendReminderApi } from "lib/Api/Authentication";
import { LoginFormContext } from "context/loginFormCtx";

function TenantsDetails() {
  const { id } = useParams();

  const navigate = useNavigate();

  const { setNotification,showMessageNotification } = useContext(LoginFormContext);

  const [tenantDetails, setTenantDetails] = useState(null);

  // state for handling stripe customer availability
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [tenantData, setTenantData] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);

  const [disableReminderBtn, setDisableReminderBtn] = useState(false);
  
  const dataTableData = {
    columns: [
      {
        Header: "Subscription",
        accessor: "subscription",
        filterInputType: "text",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value || "-"} />,
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        filterInputType: "number",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Period Start",
        accessor: "period_start",
        filterInputType: "date",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Period End",
        accessor: "period_end",
        filterInputType: "date",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "status",
        filterInputType: "select",
        sort: true,
        filterOptions: [
          { value: "draft", label: "Draft" },
          { value: "open", label: "Open" },
          { value: "paid", label: "Paid" },
          { value: "uncollectible", label: "Uncollectible" },
          { value: "void", label: "Void" },
        ],
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Actions",
        accessor: "invoice_pdf",
        sort: false,
        Cell: ({ value }) => (
          <MDBox>
            <IconButton
              onClick={() => {
                const URL = value;
                window.open(URL, "_blank");
              }}
            >
              <Download />
            </IconButton>
          </MDBox>
        ),
      },
    ],

    rows: [],
  };

  const [isLoading, setIsLoading] = useState(false);

  const [tableData, setTableData] = useState({
    columns: dataTableData.columns,
    rows: [],
  });

  const getTenantDetails = async (tenantId) => {
    try {
      setIsLoading(true);
      const response = await getTenantById(tenantId);
      if (response?.status === 200) {
        const responseData = response?.data?.data;
        console.log('responseData: ', responseData);
        if (!responseData?.isStripeCustomerAvailable) {
          setShowErrorMessage(true);
          setIsLoading(false);
          return;
        }
        setTenantDetails(responseData);
        setTenantData(responseData?.tenantData);
        setInvoices(responseData?.invoices);
        setUpcomingInvoice(responseData?.upcomingInvoice);

        const tempRows = responseData?.invoices?.data?.map((invoice) => {
          return {
            subscription: invoice.subscription,
            amount_paid: (invoice.amount_paid / 100).toFixed(2),
            period_start: moment(invoice.period_start * 1000).format("MM/DD/YYYY"),
            period_end: moment(invoice.period_end * 1000).format("MM/DD/YYYY"),
            status: invoice.status,
            invoice_pdf: invoice.invoice_pdf,
          };
        });

        setTableData({
          ...tableData,
          rows: tempRows,
        });
      } else {
        // navigate("/tenants");
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error (getTenantDetails): ", error);
      // navigate("/tenants");
    }
  };

  const handleSendReminder = async () => {
    setDisableReminderBtn(true);
    try {
      const response = await sendReminderApi(upcomingInvoice);

      if (response?.status === 200) {
        setNotification({
          color: "success",
          icon: "check",
          title: "Success",
          content: 'Reminder sent successfully',
          open: true,
        });
      } else {
        setNotification({
          color: "error",
          icon: "check",
          title: "Error",
          content: response?.data?.message,
          open: true,
        });
      }
    } catch (error) {
      setNotification({
        color: "error",
        icon: "check",
        title: "Error",
        content: error?.message || "Something went wrong!",
        open: true,
      });
    }
    setDisableReminderBtn(false);
  };

  useEffect(() => {
    getTenantDetails(id);
  }, [id]);

  const handleStatusChange = async () => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to change the status of this tenant?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateTenantStatus(tenantData?.id, { status: tenantData?.status ? 0 : 1 });
        if (response?.status === 200) {
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
            });
          await getTenantDetails(id)
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  return (
    <CustomPageLayout>
      {
        showErrorMessage && (
          <MDBox py={3}>
        <Card>
          <Stack lineHeight={1} spacing={2} sx={{ p: 3 }} alignItems="center">
            <MDTypography variant="body2" fontWeight="medium">
            It appears that there is an issue with the Stripe account configuration for this tenant. 
            </MDTypography>
          </Stack>
        </Card>
      </MDBox>
        )
      }
      
      {tenantDetails && (
        <MDBox py={3}>
          <Card>
            <Stack lineHeight={1} spacing={2} sx={{ p: 3 }}>
              <Grid container sx={{ pb: 3 }} justifyContent={"space-between"}>
                <Grid item>
                  <MDTypography variant="h5" fontWeight="medium">
                    {tenantData?.company_name || "Tenant Details"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    Tenant details and its related information.
                  </MDTypography>
                </Grid>
                 <MDTypography variant="button" color="text">
                    <Switch 
                      inputProps={{
                        'aria-label': 'statuc switch',
                      }} 
                      checked={ tenantData?.status=== 1 ? true : false }
                      onChange={handleStatusChange}
                      color="default"
                    />
                  </MDTypography>
              </Grid>

              {/* Tenant Details */}
              <Stack>
                <Paper variant="outlined" sx={{ p: "20px" }}>
                  <Stack spacing={1}>
                    <Stack spacing={1}>
                      <Stack sx={{ typography: "h5" }}>Tenant Details</Stack>
                    </Stack>

                    <Stack direction="row" spacing={1} sx={{ typography: "body2" }}>
                      <Stack sx={{ color: "text.primary" }}>Company Name :</Stack>
                      <Stack sx={{ color: "text.secondary" }}>{tenantData?.company_name}</Stack>
                    </Stack>

                    <Stack direction="row" spacing={1} sx={{ typography: "body2" }}>
                      <Stack sx={{ color: "text.primary" }}>Agency Name :</Stack>
                      <Stack sx={{ color: "text.secondary" }}>{tenantData?.agency_name}</Stack>
                    </Stack>

                    <Stack direction="row" spacing={1} sx={{ typography: "body2" }}>
                      <Stack sx={{ color: "text.primary" }}>Status :</Stack>
                      <Stack sx={{ color: tenantData?.status ? "primary.main" : "text.disabled" }}>
                        {tenantData?.status ? "Active" : "Inactive"}
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>

              {/* Tenant Overview */}
              <Stack>
                <Paper variant="outlined" sx={{ p: "20px", textTransform: "capitalize" }}>
                  <Stack spacing={1}>
                    <Stack spacing={1}>
                      <Stack sx={{ typography: "h5" }}>Tenant Overview</Stack>
                    </Stack>

                    <Stack spacing={10} direction="row">
                      <Stack spacing={1} sx={{ typography: "body2" }}>
                        <Stack sx={{ color: "text.primary" }}>Users count</Stack>
                        <Stack sx={{ color: "primary.main" }}>
                          {tenantData?.tenantUsers?.length}
                        </Stack>
                      </Stack>

                      <Stack spacing={1} sx={{ typography: "body2" }}>
                        <Stack sx={{ color: "text.primary" }}>Branches count</Stack>
                        <Stack sx={{ color: "primary.main" }}>{tenantDetails?.branches}</Stack>
                      </Stack>

                      <Stack spacing={1} sx={{ typography: "body2" }}>
                        <Stack sx={{ color: "text.primary" }}>Invoices count</Stack>
                        <Stack sx={{ color: "primary.main" }}>{invoices?.data?.length}</Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>

              {/* Upcoming Invoice */}
              {
                upcomingInvoice && (
                  <Stack>
                <Paper variant="outlined" sx={{ p: "20px" }}>
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5">Upcoming Invoice</Typography>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSendReminder}
                        disabled={disableReminderBtn}
                      >
                        Send reminder
                      </Button>
                    </Stack>

                    <Stack spacing={1} sx={{ mt: 5 }}>
                      <Stack sx={{ typography: "h6" }}>Summary</Stack>

                      <Divider />

                      <TableBody
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell>Customer</TableCell>
                          <TableCell>
                            <Typography variant="body2" color="text.primary">
                              {upcomingInvoice?.customer_name}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Customer Email</TableCell>
                          <TableCell>{upcomingInvoice?.customer_email}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Stack>

                    <Stack spacing={1} sx={{ mt: 5 }}>
                      <Table>
                        <TableBody
                          sx={{
                            [`& .${tableCellClasses.root}`]: {
                              typography: "h6",
                            },
                          }}
                        >
                          <TableCell sx={{ width: "70%" }}>Description</TableCell>
                          <TableCell>Qty</TableCell>
                          <TableCell>Unit Price</TableCell>
                          <TableCell>Amount</TableCell>
                        </TableBody>

                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Stack spacing={1} direction="row" sx={{ fontWeight: 700 }}>
                                <Stack>
                                  {upcomingInvoice?.lines?.data[0]?.plan?.nickname || "Plan"}
                                </Stack>
                                <Stack>{`(${moment(upcomingInvoice?.period_start * 1000).format(
                                  "DD MMM YYYY"
                                )} - ${moment(upcomingInvoice?.period_end * 1000).format(
                                  "DD MMM YYYY"
                                )})`}</Stack>
                              </Stack>
                            </TableCell>
                            <TableCell>{upcomingInvoice?.lines?.data[0]?.quantity}</TableCell>
                            <TableCell>
                              ${upcomingInvoice?.lines?.data[0]?.price?.unit_amount / 100}
                            </TableCell>
                            <TableCell>
                              $
                              {upcomingInvoice?.lines?.data[0]?.quantity *
                                (upcomingInvoice?.lines?.data[0]?.price?.unit_amount / 100)}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              <Stack direction="column" rowGap={2}>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  Subtotal
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  Total excluding tax
                                </Typography>

                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  Total
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  Amount due
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="column" rowGap={2}>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  ${upcomingInvoice?.subtotal / 100 ?? "-"}
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  ${upcomingInvoice?.subtotal_excluding_tax / 100 ?? "-"}
                                </Typography>

                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  ${upcomingInvoice?.total / 100 ?? "-"}
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  ${upcomingInvoice?.amount_due / 100 ?? "-"}
                                </Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
                )
              }
              

              {/* Invoices */}
              <Stack>
                <Paper variant="outlined" sx={{ p: "20px", textTransform: "capitalize" }}>
                  <Stack spacing={1}>
                    <Stack spacing={1}>
                      <Stack sx={{ typography: "h5" }}>Tenant's Invoices</Stack>
                    </Stack>

                    <Stack>
                      <LocalDataTable isLoading={isLoading} table={tableData} isCSVExport />
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Stack>
          </Card>
        </MDBox>
      )}
    </CustomPageLayout>
  );
}

export default TenantsDetails;
